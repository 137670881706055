import React from 'react'



const IndexPage = () => (
  <div style={{
    backgroundImage:"url('/images/vorspiel2020_press_web.jpg')",
    backgroundSize:"cover",
    position:"fixed",
    backgroundPosition:"center center",
    left:0,right:0,top:0,bottom:0
  }} />
)

export default IndexPage
